.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.App-img {
  pointer-events: none;
}

@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@300&family=Caveat&family=Fraunces:wght@400;500&family=Grenze&family=IBM+Plex+Serif&family=Jost&family=Labrada:wght@500&family=Macondo&family=Playfair+Display:ital@0;1&family=Poltawski+Nowy&family=Press+Start+2P&family=Special+Elite&display=swap');
